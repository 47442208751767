import React from "react";
import img1 from "../Assets/Security/img1.png";
import { Shield, Heart, Lock, PhoneCall } from "react-feather";
import img6 from "../Assets/Home/image.png";
import img9 from "../Assets/Home/009 1.png";
import img2 from "../Assets/Security/Safety.png";
import img3 from "../Assets/Security/Safety.jpeg";
import "./circule.css";
import ShinyButton from "./magicui/ShinyButton";
function SecurityComp() {
  return (
    <>
      <div className="w-full flex flex-col pt-[7rem] items-center  bg-[#f8faf1] lg:pt-16">
        <div className="w-full max-w-[81rem]  flex flex-col items-center gap-y-8">
          {/* Title Section */}
          <div className="flex flex-col absolute gap-y-5 lg:mb-[5rem] items-center text-center">
            <span className="text-[#6B6B6B]">Trust & Safety</span>
            <h1 className="text-[#3B3B3B]  font-bold text-2xl md:text-4xl lg:text-5xl xl:text-5xl ">
              Security & Privacy Standards <br /> That Keep Your Data Safe
            </h1>
          </div>

          <div className=" w-max pt-[2rem] lg:pt-0 flex overflow-hidden justify-center  ">
            <img
              src={img2}
              className="lg:w-full hidden md:block w-[30rem] max-w-[65rem] "
              alt=""
            />
            <img src={img3} className="block  md:hidden w-[20rem]  " alt="" />
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col text-[#3B3B3B] items-center bg-white p-4 md:p-8 lg:p-8">
        <div className="w-full max-w-[81rem] grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-2">
          {/* GDPR Section */}
          <div className="bg-[#F5F6F2] flex flex-col items-start p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="w-16 h-16 bg-[#041255] rounded-full flex justify-center items-center mb-4">
              <Shield color="white" size={30} />
            </div>
            <span className="md:text-[1.5rem] text-[1.4rem] font-semibold mb-2">
              Protecting Your Data
            </span>
            <p className="text-base text-start">
              Labops follows strict data security protocols, ensuring your
              health information is protected through advanced encryption and
              secure access systems, giving you peace of mind about your
              sensitive data.
            </p>
          </div>

          {/* HIPAA Section */}
          <div className="bg-[#F5F6F2] flex flex-col items-start p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="w-16 h-16 bg-[#041255] rounded-full flex justify-center items-center mb-4">
              <Heart color="white" size={30} />
            </div>

            <span className="md:text-[1.5rem] text-[1.4rem] font-semibold mb-2">
              Trust and Safety
            </span>
            <p className="text-base text-start">
              At Labops, your trust means everything to us. We’re committed to
              keeping your health data secure with the highest standards. Every
              step we take ensures your privacy, so you can feel confident that
              your information is in safe hands.
            </p>
          </div>

          {/* ISO 27001 Section */}
          <div className="bg-[#F5F6F2] flex flex-col items-start p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="w-16 h-16 bg-[#041255] rounded-full flex justify-center items-center mb-4">
              <Shield color="white" size={30} />
            </div>

            <span className="md:text-[1.5rem] text-[1.4rem] font-semibold mb-2">
              No Third-Party Data Sharing
            </span>
            <p className="text-base text-start">
              Your health data is personal, and at Labops, we treat it with the
              utmost care. We don’t share your information with any third
              parties—what you share stays strictly between us, ensuring your
              privacy is never compromised.
            </p>
          </div>

          {/* SOC 2 Type II Section */}
          <div className="bg-[#F5F6F2] flex flex-col items-start p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="w-16 h-16 bg-[#041255] rounded-full flex justify-center items-center mb-4">
              <Lock color="white" size={30} />
            </div>

            <span className="md:text-[1.5rem] text-[1.4rem] font-semibold mb-2">
              Our Powerful Cloud Architecture
            </span>
            <p className="text-base text-start text-[#6B6B6B]">
              Labops uses a robust, secure cloud system designed to protect your
              data. Our advanced architecture ensures that your health
              information remains private and secure at every step of your
              journey with us.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full h-full flex flex-col items-center bg-white py-16 lg:py-16 px-4 md:px-8 lg:px-8 text-[#3B3B3B]">
        <div className="w-full h-full  max-w-[81rem] flex flex-col lg:flex-row gap-10 items-center">
          {/* Left Image Section */}
          <div className="flex-1 ">
            <img
              src={img6}
              className="w-full max-h-[45rem] rounded-xl object-cover"
              alt=""
            />
          </div>

          {/* Right Content Section */}
          <div className="flex-1 flex flex-col justify-between gap-y-5">
            {/* Title and Description */}
            <div className="w-full flex flex-col gap-4">
              <span className="lg:text-3xl text-[1.8rem]  font-semibold text-[#3B3B3B] ">
                Your Health Data, Safeguarded with the Highest Standards
              </span>
              <span className="text-[#6B6B6B] h-[8rem] leading-relaxed">
                At Labops, your privacy is our priority. We use advanced
                security to protect your health data, from test booking to
                report access. With top encryption, secure cloud storage, and
                global compliance, we ensure your medical information stays safe
                and private.
              </span>
            </div>

            {/* Bullet Points Section */}
            <div className="w-full flex flex-col gap-y-6 lg:pl-5">
              {/* Item 1 */}
              <div className="w-full flex flex-col border-b-2 pb-5 border-b-[#F0F1F3]">
                <span className="text-[1.2rem] font-medium">
                  1. Data Encryption
                </span>
                <span className="text-[#6B6B6B]">
                  Your health data is fully encrypted during transmission and
                  storage, preventing unauthorized access and ensuring secure
                  data handling.
                </span>
              </div>

              {/* Item 2 */}
              <div className="w-full flex flex-col border-b-2 pb-5 border-b-[#F0F1F3]">
                <span className="text-[1.2rem] font-medium">
                  2. Secure Cloud-Based Storage
                </span>
                <span className="text-[#6B6B6B]">
                  We use highly secure cloud storage solutions to protect your
                  medical records from cyber threats, giving you safe and easy
                  access to your health information.
                </span>
              </div>

              {/* Item 3 */}
              <div className="w-full flex flex-col border-b-2 pb-5 border-b-[#F0F1F3]">
                <span className="text-[1.2rem] font-medium">
                  3. Multi-Factor Authentication (MFA)
                </span>
                <span className="text-[#6B6B6B]">
                  Labops strengthens account security with MFA, ensuring that
                  only authorized users can access sensitive health information
                </span>
              </div>

              {/* Item 4 */}
              <div className="w-full flex flex-col border-b-2 pb-5 border-b-[#F0F1F3]">
                <span className="text-[1.2rem] font-medium">
                  4. Compliance with Global Privacy Standards
                </span>
                <span className="text-[#6B6B6B]">
                  Labops meets healthcare regulations like HIPAA and GDPR,
                  providing top-level compliance and security for your health
                  data across all platforms.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full relative flex flex-col items-center bg-white py-0 px-4 md:px-8 lg:px-6 text-[#3B3B3B]">
        <div className="w-full  max-w-[81rem] flex flex-col gap-y-10 lg:flex-row items-center lg:gap-x-10">
          {/* Text Section */}
          <div className="flex-1 flex flex-col items-center lg:items-start gap-y-5 text-center lg:text-left">
            <h2 className="lg:text-6xl text-4xl leading-[3rem] font-bold lg:leading-[4.5rem]">
              We're Ready to Help, Contact Us Anytime!
            </h2>
            <p className="text-[#6B6B6B] text-base lg:text-lg">
              Got questions or need support? We're here to listen and assist you
              with whatever you need.
            </p>
            <div className="flex justify-start">
              <a href="/contact">
                <button className="bg-gradient-to-r from-[#4C8DF5] w-[16rem] mt-[1rem] to-[#1A73E8] text-[16px] font-semibold  max-w-[12rem] h-[3.5rem] rounded-lg flex items-center justify-center text-white shadow-md hover:shadow-lg transition-all duration-300 ease-in-out">
                  <span className="flex items-center">
                    <PhoneCall className="mr-2 w-5 h-5" />{" "}
                    {/* Phone icon with size */}
                    <span className="text-[16px] text-white">
                      Contact us now
                    </span>
                  </span>
                </button>
              </a>
            </div>
          </div>

          {/* Image Section */}
          <div className="flex-1 relative">
            <img src={img9} className="w-full object-cover" alt="Contact Us" />
          </div>
        </div>
      </div>
    </>
  );
}

export default SecurityComp;
