import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Copy,
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  Youtube,
} from "react-feather";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";

import BlogContent from "./BlogContent";
const BlogDetailComp = () => {
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [email, setEmail] = useState("");
  const [blog, setBlog] = useState({});
  const { slug } = useParams();

  const [loader, setLoader] = useState(false);
  const subEmail = async () => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email is provided
    if (!email?.length) {
      toast.error("Email is required");
      return;
    }

    // Validate the email format
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    setLoader(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/management/subscribe-email/`,
        {
          email: email,
        }
      );
      setLoader(false);
      toast.success("Subscribed successfully!");
      setEmail(""); // Clear the email input
    } catch (error) {
      setLoader(false);
      toast.error("An error occurred. Please try again.");
    }
  };

  const getBlog = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/blogs/${slug}`
      );
      setBlog(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const copyLink = () => {
    // Get the current URL
    const currentLink = window.location.href;

    // Use the clipboard API to copy the link
    navigator.clipboard
      .writeText(currentLink)
      .then(() => {
        toast.success("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  useEffect(() => {
    getBlog();
  }, []);

  console.log(blog.content);

  return (
    <div className="bg-gray-50 pt-[5rem] lg:pt-0 min-h-screen flex flex-col">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-blue-100 to-gray-100 py-10">
        <div className="max-w-[85rem] mx-auto  relative">
          {/* Image */}
          <img
            src={
              `${process.env.REACT_APP_BASEURL}/${blog.image}` ||
              "https://source.unsplash.com/800x400/?pathology"
            }
            alt={blog.image_description || "Blog image"}
            className="w-full h-[600px] object-cover"
          />

          {/* Overlay for Title and Metadata */}
          <div className="absolute inset-0 flex flex-col items-center justify-center text-white bg-black bg-opacity-[0] p-11 rounded-lg">
            <h2
              className="lg:text-5xl absolute left-6 bottom-[6rem] text-3xl font-extrabold mb-2 text-start"
              style={{ textShadow: "6px 6px 8px rgba(0, 0, 0, 0.8)" }}
            >
              {blog.title}
            </h2>
            <div className="text-gray-300 flex flex-wrap gap-3 lg:gap-0 absolute bottom-[1.9rem] left-6 text-sm lg:text-base  text-center">
              {/* by {blog.author?.name || "Unknown"} |{" "} */}
              <span className="bg-blue-500 text-white px-3 py-2 text-sm rounded-[0.2rem] font-semibold mr-2 shadow-md">
                {blog?.publish &&
                  new Date(blog?.publish)
                    .toISOString()
                    .split("T")[0]
                    .replace(/-/g, "-")}{" "}
              </span>
              <span className="bg-blue-500 text-white px-3 py-2 text-sm rounded-[0.2rem] font-semibold mr-2 shadow-md">
                {`${blog?.minutes_read} min read`}
              </span>
              <span className="bg-blue-500 text-white px-3 py-2 text-sm rounded-[0.2rem] font-semibold mr-2 shadow-md">
                {blog.category?.name || "General"}
              </span>
            </div>
          </div>

          {/* Short Description */}
        </div>
        <div className="max-w-[85rem] mx-auto pt-6 relative">
          <p className="text-gray-700 text-lg mt-0">{blog.short_description}</p>
        </div>
      </section>
      <h2></h2>

      {/* Blog Content Section */}
      <main className="flex-1 max-w-5xl mx-auto p-8 bg-white shadow-lg  rounded-lg my-6">
        <BlogContent content={blog?.content} />

        <div className="flex flex-col justify-between items-start lg:flex-row lg:items-center  border-t border-gray-300 pt-4 mb-6">
          <p className="lg:text-2xl text-lg font-semibold mb-4">
            Share this post:
          </p>
          <div className="flex space-x-6 justify-start md:justify-center w-full md:w-auto">
            <span
              onClick={copyLink} // Copy on click
              className="bg-blue-500 p-2 cursor-pointer rounded-full text-white hover:bg-blue-600 shadow-lg transition duration-300 ease-in-out transform hover:scale-110"
              aria-label="Copy link"
            >
              <Copy className="h-6 w-6" />
            </span>
            <a
              href="#"
              className="bg-blue-500 p-2 rounded-full text-white hover:bg-blue-600 shadow-lg transition duration-300 ease-in-out transform hover:scale-110"
              aria-label="Facebook"
            >
              <Facebook className="h-6 w-6" />
            </a>
            <a
              href="https://x.com"
              className="bg-blue-500 p-2 rounded-full text-white hover:bg-blue-600 shadow-lg transition duration-300 ease-in-out transform hover:scale-110"
              aria-label="Twitter"
            >
              <Twitter className="h-6 w-6" />
            </a>
          </div>
        </div>
        {/* Author Bio */}
        <div className="mt-10 border-t border-gray-300 pt-4">
          <p className="lg:text-2xl text-lg font-semibold mb-4">
            About the Author
          </p>
          <div className="flex items-center">
            {/* <img
              src="https://via.placeholder.com/80"
              alt="Deb Mallik"
              className="rounded-full w-[4rem] h-[4rem] mr-4"
            /> */}
            <div>
              <p className="font-semibold text-[1.1rem] text-[#555]">
                {blog?.author}
              </p>
              {/* <p className="text-gray-600">CEO of Labops</p> */}
            </div>
          </div>
        </div>
        {/* Newsletter Section */}
        <section className="bg-gray-100 p-6 rounded-lg mb-6 mt-10">
          <p className="lg:text-2xl text-lg font-semibold mb-4">
            Subscribe to our Newsletter
          </p>
          <div className="flex flex-col md:flex-row">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="flex-1 p-3 border border-gray-300 rounded-md mb-2 md:mr-2 md:mb-0"
              placeholder="Your email address"
            />
            <button
              onClick={() => subEmail()}
              type="submit"
              className="bg-blue-600 text-white flex justify-center items-center h-[3rem] w-[6.5rem] rounded-md transition-colors duration-200 hover:bg-blue-700"
            >
              {loader ? (
                <ThreeDots
                  visible={true}
                  height="50"
                  width="50"
                  color="white"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                "  Subscribe"
              )}
            </button>
          </div>
        </section>
      </main>
    </div>
  );
};

export default BlogDetailComp;
