import React, { useState } from "react";
import img1 from "../Assets/About/img1.png";
import img2 from "../Assets/About/Img2.png";
import img3 from "../Assets/About/Img3.png";
import img4 from "../Assets/About/img4.png";
import img5 from "../Assets/About/Img5.png";
import img7 from "../Assets/Home/Rectangle 158.png";

import img9 from "../Assets/Home/009 1.png";
import { Check, Target, Info, PhoneCall } from "react-feather";

import {
  Activity,
  Award,
  ChevronDown,
  ChevronUp,
  Cpu,
  Lock,
} from "react-feather";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ShinyButton from "./magicui/ShinyButton";

function AboutComp() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const accordionData = [
    {
      question: "Is Labops suitable for both pathology and diagnostic labs?",
      answer:
        "Yes, Labops is designed to cater to the needs of both pathology and diagnostic labs, making it a versatile solution for various laboratory settings.",
    },
    {
      question: "How secure is Labops for health data?",
      answer:
        "Labops prioritizes your privacy and security. With end-to-end encryption, HIPAA compliance, and regular audits, your health data is safeguarded with the highest standards.",
    },
    {
      question: "Can I access Labops from mobile devices?",
      answer:
        "Yes, Labops is accessible from both desktop and mobile devices, ensuring you can manage your lab anytime, anywhere.",
    },
    {
      question: "Does Labops support multi-location labs?",
      answer:
        "Absolutely! Labops is equipped to handle multi-location labs efficiently, allowing you to manage all your branches from a single dashboard.",
    },
    {
      question: "Does Labops support multi-location labs?",
      answer:
        "Absolutely! Labops is equipped to handle multi-location labs efficiently, allowing you to manage all your branches from a single dashboard.",
    },
  ];
  return (
    <>
      <div className="w-full flex flex-col items-center bg-[#ededed] py-12 px-4 pt-24 lg:pt-40">
        <div className="w-full max-w-[81rem]  lg:max-h-[50rem] flex flex-col lg:flex-row gap-4 lg:gap-8 items-center lg:items-start">
          {/* Text and Image Block 1 */}
          <div className="flex  flex-col flex-1  text-center lg:text-left">
            <div className="flex h-[15rem] flex-col  gap-6 mb-0 lg:mb-0">
              <span className="text-4xl text-start lg:text-5xl xl:text-6xl font-bold text-gray-800">
                About Us
              </span>
              <p className="text-gray-700 text-start text-base  leading-relaxed">
                Labops connects diagnostic labs and patients seamlessly, making
                healthcare more affordable and accessible. We empower labs with
                cutting-edge tools while offering patients savings, convenience,
                and reliable care—all in one platform.
              </p>
            </div>
            <div className="relative w-full  mx-auto lg:mx-0">
              <img
                src={img2}
                alt="Labops"
                className="w-full lg:h-[30rem] shadow-md  object-cover"
              />
            </div>
          </div>

          {/* Text and Image Block 2 */}
          <div className="lg:w-[35%] w-full text-center lg:text-left">
            <div className="relative w-full  mx-auto lg:mx-0">
              <img
                src={img1}
                alt="Healthcare Precision"
                className="w-full  h-[30rem] shadow-md  lg:h-[45rem]  object-cover"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-center bg-white py-12 px-4 ">
        <div className="w-full  max-w-[81rem]  flex flex-col lg:flex-row gap-x-5">
          <div className="flex flex-1 flex-col w-full ">
            <div className="gap-y-4 pb-10 flex flex-col border-b-[#F3EFE7] border-b-[2px]">
              <span className="lg:text-4xl text-3xl text-[#3B3B3B] font-[500] ">
                Story behind Labops
              </span>
              <span className="text-[#333333]">
                We come from a region where, within just 5 sq. km, there are
                over 150 labs and 400+ doctor clinics. Patients struggle to find
                the right services, while labs face challenges managing them
                efficiently. Having witnessed these issues firsthand, solving
                them has become a personal mission. We want to improve
                healthcare access and management in the place we call home.
              </span>
            </div>

            <div className="flex-1 flex items-start justify-center  py-10 gap-y-6 flex-col">
              <div className="flex items-center justify-center gap-x-3">
                <div className="w-[1.3rem] flex items-center justify-center h-[1.3rem] rounded-full bg-[#488FED]">
                  <Check size={14} color="white"></Check>
                </div>
                <span className="text-[#3B3B3B]">
                  Collaborative Achievements
                </span>
              </div>{" "}
              <div className="flex items-center justify-center gap-x-3">
                <div className="w-[1.3rem] flex items-center justify-center h-[1.3rem] rounded-full bg-[#488FED]">
                  <Check size={14} color="white"></Check>
                </div>
                <span className="text-[#3B3B3B]">Team Achievements</span>
              </div>{" "}
              <div className="flex items-center justify-center gap-x-3">
                <div className="w-[1.3rem] flex items-center justify-center h-[1.3rem] rounded-full bg-[#488FED]">
                  <Check size={14} color="white"></Check>
                </div>
                <span className="text-[#3B3B3B]">Client Success Stories</span>
              </div>{" "}
              <div className="flex items-center justify-center gap-x-3">
                <div className="w-[1.3rem] flex items-center justify-center h-[1.3rem] rounded-full bg-[#488FED]">
                  <Check size={14} color="white"></Check>
                </div>
                <span className="text-[#3B3B3B]">Future Aspirations</span>
              </div>{" "}
              <div className="flex items-center justify-center gap-x-3">
                <div className="w-[1.3rem] flex items-center justify-center h-[1.3rem] rounded-full bg-[#488FED]">
                  <Check size={14} color="white"></Check>
                </div>
                <span className="text-[#3B3B3B]">Community Engagement</span>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <img src={img2} className="w-full shadow-md" alt="" />
          </div>
        </div>
      </div>

      <div className="bg-[#F3EFE7] py-12 px-4  flex flex-col items-center">
        <div className=" max-w-[81rem]  mx-auto flex flex-col-reverse lg:flex-row lg:space-x-4 ">
          {/* Left Column */}
          <div className="w-full lg:w-[40%] flex flex-col lg:gap-y-4">
            {/* Card 1 */}
            <div className="bg-white  p-6 lg:p-8 flex flex-col items-start  shadow-md">
              <div className="flex items-start space-x-4">
                <div className="flex flex-col gap-y-3">
                  <div className="w-10 h-10 flex justify-center items-center bg-[#488FED] text-white rounded-full">
                    <Target size={28} />
                  </div>
                  <h2 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-2">
                    Our Mission
                  </h2>
                  <p className="text-gray-600">
                    We aim to revolutionize healthcare by connecting labs,
                    patients, and technology. Our mission is to create a
                    seamless diagnostic experience for both labs and
                    patients—making healthcare more affordable and accessible
                    for everyone. By empowering labs with advanced tools and
                    offering patients AI-driven health insights, we’re
                    transforming healthcare management into a connected and
                    efficient process.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 2 */}
            <div className="bg-white  p-6 lg:p-8 flex flex-col items-start  shadow-md">
              <div className="flex items-start space-x-4">
                <div className="flex flex-col gap-y-3">
                  <div className="w-10 h-10 flex justify-center items-center bg-[#488FED] text-white rounded-full">
                    <Info size={28} />
                  </div>
                  <h2 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-2">
                    Our Vision
                  </h2>
                  <p className="text-gray-600">
                    Our vision is to become the backbone of India’s healthcare
                    ecosystem, where medical facilities and patients are
                    seamlessly connected. We envision a future where diagnostic
                    labs thrive with efficiency, patients receive quality care
                    effortlessly, and everyone has access to affordable
                    healthcare. Through innovation, we will drive this
                    transformation, reshaping healthcare one step at a time.
                  </p>
                </div>
              </div>
            </div>

            {/* Image */}
            <img
              src={img4}
              alt="Description"
              className="w-full flex-1 h-auto object-cover  shadow-md"
            />
          </div>

          {/* Right Column */}
          <div className="w-full lg:w-[60%] flex flex-col-reverse lg:flex-col  lg:gap-y-4">
            {/* Main Image */}
            <img
              src={img3}
              alt="Description"
              className="w-full h-[46rem] object-cover  shadow-md"
            />

            {/* Content Blocks */}
            <div className="flex flex-1 flex-col lg:flex-row lg:space-x-4  lg:gap-y-0">
              {/* Content Block 1 */}
              <div className="flex-1 bg-white  p-6 lg:p-8  shadow-md">
                <div className="flex items-start space-x-4 mb-0">
                  <div className="flex flex-col gap-y-3">
                    <div className="w-10 h-10 flex justify-center items-center bg-[#488FED] text-white rounded-full">
                      <Target size={28} />
                    </div>
                    <h2 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-2">
                      Our Approach
                    </h2>
                    <p className="text-gray-600">
                      We combine advanced technology with human care, creating a
                      platform that empowers labs, supports patients and
                      doctors, transforms healthcare access for everyone.
                    </p>
                  </div>
                </div>
              </div>

              {/* Content Block 2 */}
              <div className="flex-1 bg-white p-6 lg:p-8 shadow-md">
                <div className="flex items-start space-x-4">
                  <div className="flex flex-col gap-y-3">
                    <div className="w-10 h-10 flex justify-center items-center bg-[#488FED] text-white rounded-full">
                      <Info size={28} />
                    </div>
                    <h2 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-2">
                      Our Values
                    </h2>
                    <p className="text-gray-600">
                      Trust, innovation, and empathy guide everything we
                      do—delivering reliable solutions that improve healthcare
                      for labs and patients alike.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="bg-[#F3EFE7] py-12 px-4 flex flex-col items-center">
        <div className=" max-w-[81rem]  w-full mx-auto flex flex-col gap-y-12">
          <div className="w-full flex flex-col items-center">
            <span className="text-[#6B6B6B]">Members</span>
            <span className="text-[#3B3B3B] text-3xl lg:text-4xl">
              Meet Our Team
            </span>
          </div>

       
          <div className="md:hidden w-full flex justify-center">
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              navigation
              centeredSlides={true}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
            >
              {[...Array(8)].map((_, index) => (
                <SwiperSlide
                  key={index}
                  className="flex flex-col  items-center"
                >
                  <div className="flex justify-center">
                    <img
                      src={img5}
                      className="w-[13rem] rounded-md mb-2"
                      alt="Team Member"
                    />
                  </div>

                  <div className="flex flex-col items-center">
                    <span className="text-[1.2rem] font-[500] text-[#3B3B3B]">
                      Esther Howards
                    </span>
                    <span className="text-[#6B6B6B]">Founder & CEO</span>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

        
          <div className="hidden md:grid lg:gap-10 md:grid-cols-3  lg:grid-cols-4 xl:grid-cols-5 gap-10">
            {[...Array(8)].map((_, index) => (
              <div key={index} className="flex flex-col items-center">
                <img
                  src={img5}
                  className="w-[13rem] rounded-md mb-2"
                  alt="Team Member"
                />
                <span className="text-[1.2rem] font-[500] text-[#3B3B3B]">
                  Esther Howards
                </span>
                <span className="text-[#6B6B6B]">Founder & CEO</span>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      <div className="w-full h-full flex flex-col items-center bg-[#F2F4EF] py-16 lg:py-16 px-4 md:px-8 lg:px-8 text-[#3B3B3B]">
        <div className="w-full flex max-w-[81rem] flex-col lg:flex-row gap-y-5 gap-x-10 items-start">
          {/* Left Text Section */}
          <div className="lg:flex-1 w-full">
            <div className="w-full flex flex-col gap-y-3">
              <span className="text-[#3B3B3B] text-3xl md:text-4xl font-bold">
                Frequently Asked Questions
              </span>
              <span className="text-[#6B6B6B] text-lg">
                Get answers to the most commonly asked questions about Labops.
              </span>
            </div>

            {/* Accordion */}
            <div className="mt-8 flex flex-col gap-y-2">
              {accordionData.map((item, index) => (
                <div
                  key={index}
                  className={`border-b-2 border-[#E0E0E0] rounded-xl py-4 transition-all duration-500 ease-in-out ${
                    activeIndex === index
                      ? "bg-white shadow-lg rounded-md"
                      : "bg-white"
                  }`}
                >
                  <div
                    className="flex justify-between items-center px-5 py-2 cursor-pointer transition-transform transform"
                    onClick={() => toggleAccordion(index)}
                  >
                    <span className="text-[#3B3B3B] text-lg font-medium w-[90%]">
                      {item.question}
                    </span>
                    <span className="text-[#6B6B6B] bg-[#D9D9D9] flex justify-center items-center h-[1.6rem] w-[1.6rem] rounded-lg text-xl transition-transform duration-500 ease-in-out">
                      {activeIndex === index ? (
                        <ChevronUp size={20} />
                      ) : (
                        <ChevronDown size={20} />
                      )}
                    </span>
                  </div>

                  {/* Accordion content with smoother transition */}
                  <div
                    className={`overflow-hidden px-5 transition-all duration-700 ease-in-out ${
                      activeIndex === index
                        ? "max-h-[1000px] opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                    style={{
                      transition:
                        "max-height 0.7s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.7s ease-in-out",
                    }}
                  >
                    <div className="mt-4 text-[#6B6B6B] text-base">
                      <p>{item.answer}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Right Image Section */}
          <div className="md:flex-1 w-full lg:max-w-[35%]">
            <img
              src={img7}
              className="rounded-lg w-full object-cover shadow-md"
              alt="FAQ Image"
            />
          </div>
        </div>
      </div>
      <div className="w-full h-full relative flex flex-col items-center bg-white py-0 px-4 md:px-8 lg:px-6 text-[#3B3B3B]">
        <div className="w-full   max-w-[81rem]  flex flex-col gap-y-10 lg:flex-row items-center lg:gap-x-10">
          {/* Text Section */}
          <div className="flex-1 flex flex-col items-center lg:items-start gap-y-5 text-center lg:text-left">
            <h2 className="lg:text-6xl text-4xl leading-[3rem] font-bold lg:leading-[4.5rem]">
              We're Ready to Help, Contact Us Anytime!
            </h2>
            <p className="text-[#6B6B6B] text-base lg:text-lg">
              Got questions or need support? We're here to listen and assist you
              with whatever you need.
            </p>
            <div className="flex justify-start">
              <a href="/contact">
                <button className="bg-gradient-to-r from-[#4C8DF5] w-[16rem] mt-[1rem] to-[#1A73E8] text-[16px] font-semibold  max-w-[12rem] h-[3.5rem] rounded-lg flex items-center justify-center text-white shadow-md hover:shadow-lg transition-all duration-300 ease-in-out">
                  <span className="flex items-center">
                    <PhoneCall className="mr-2 w-5 h-5" />{" "}
                    {/* Phone icon with size */}
                    <span className="text-[16px] text-white">
                      Contact us now
                    </span>
                  </span>
                </button>
              </a>
            </div>
          </div>

          {/* Image Section */}
          <div className="flex-1 relative">
            <img src={img9} className="w-full object-cover" alt="Contact Us" />
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutComp;
